import React from "react";
import styles from "../../../styles/General.module.css";

import Img0 from "../../../assets/images/img0.png";
import Img1 from "../../../assets/images/img1.png";
import Img2 from "../../../assets/images/img2.png";
import Img3 from "../../../assets/images/img3.png";

import Arrow from "../../../assets/images/vectorArrow.png";

import ImgA from "../";
const Title = ({ title, points, packages }) => {
  return (
    <>
      <div className={styles.servicePageTitle}>
        <h1>
          <span>1 </span>
          {title}{" "}
        </h1>
        <div className={styles.servicePageTitleImages}>
          {packages?.map((p) => (
            <div>
              <img
                src={`${
                  process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
                }/images/${p.image ?? null}`}
              />
              <h1>{p.title}</h1>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.servicePagePoints}>
        {points?.length > 0 && (
          <ul>
            {points?.slice(0, points.length / 2).map((point, i) => (
              <li>
                <span>{point?.title}: </span>
                {point?.description}
              </li>
            ))}

            <span>
              <img src={Arrow} />
            </span>
          </ul>
        )}
        {points?.length > 1 && (
          <ul>
            {points?.slice(points.length / 2, points.length).map((point, i) => (
              <li>
                <span>{point?.title}: </span>
                {point?.description}
              </li>
            ))}
            <span>
              <img src={Arrow} />
            </span>
          </ul>
        )}
      </div>
    </>
  );
};

export default Title;
