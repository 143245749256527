import React from "react";
import styles from "../../../styles/General.module.css";
import CustomCarousel from "../../../components/carousel";
const Methodology = ({ data }) => {
  // const data = [
  //   {
  //     id: 1,
  //     title: "Need Assessment",
  //     description:
  //       "We begin by conducting a thorough assessment of your school's needs and goals to identify the key features and functionalities required for your workflows.",
  //   },
  //   {
  //     id: 2,
  //     title: "Software evaluation",
  //     description:
  //       "We research and evaluate potential software solutions, taking into account factors such as affordability, compatibility, scalability, and ease of use.",
  //   },
  //   {
  //     id: 3,
  //     title: "Software Selection",
  //     description:
  //       "We help you choose the best software solution for your school based on our evaluation and your specific requirements.",
  //   },
  //   {
  //     id: 4,
  //     title: "Implementation",
  //     description:
  //       "Our team of experts will install, configure, and integrate the software seamlessly with your existing systems, providing training and support throughout the process.",
  //   },
  //   {
  //     id: 5,
  //     title: "Ongoing support",
  //     description:
  //       "We offer ongoing maintenance and support to ensure that your software solution continues to meet your needs and remains up-to-date with the latest technology.",
  //   },
  // ];
  return (
    <div className={styles.methodology}>
      <div className={styles.servicePageMeth}>
        <h1>
          <span>2 </span>Methodology
        </h1>
      </div>
      <div className={styles.methodologyCarousel}>
        <CustomCarousel url={null} data={data} slidesToShow={3} card={true} />
      </div>
    </div>
  );
};

export default Methodology;
