import React from "react";
import styles from "../../../styles/General.module.css";
import BenefitsIllustration from "../../../assets/images/benefitsIllustration.png";
import { Grid } from "@mui/material";
import Poster from "../../../assets/images/poster.png";
import { useState } from "react";
const Benefits = ({ data, video, cover }) => {
  // const list = [
  //   { id: 1, name: "Improved productivity", percentage: "76" },
  //   { id: 2, name: "Enhanced collaboration", percentage: "86" },
  //   { id: 3, name: "Cost savings", percentage: "34" },
  // ];
  const [play, setPlay] = useState(false);
  const url = play
    ? // ? "https://www.youtube.com/embed/K1yp7Q1hH1c?autoplay=1"
      null
    : "";
  const MyVideo = ({ play, videoUrl, onVideoStop, onVideoPlay, image }) => {
    return (
      <div className={styles.benefitsVideo}>
        {!play && <img src={image} />}
        {
          <iframe
            autoPlay
            src={play && videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen;"
          ></iframe>
        }

        {play ? (
          <button onClick={onVideoStop} className="">
            stop
          </button>
        ) : (
          <button onClick={onVideoPlay} className="">
            watch now
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.servicePageBenefits}>
      <h1>
        <span>3 </span>Benefits
      </h1>
      <Grid container>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          // container
          // direction="column"
          // alignItems="center"
          // alignContent="center"
          // justifyContent="center"
        >
          <ul className={styles.benefitsList}>
            {data?.map((rec) => (
              <li>
                <p>{rec.title}</p>
                <span>{rec.description}</span>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <MyVideo
            videoUrl={`${
              process.env.REACT_APP_API_BASE_URL_ATTACHMENTS
            }/videos/${video ?? null}`}
            play={play}
            onVideoStop={() => setPlay(false)}
            onVideoPlay={() => setPlay(true)}
            // image={Poster}

            image={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${
              cover ?? null
            }`}
          />
        </Grid>
        <Grid
          item
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          sx={{}}
          spacing={0}
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          {" "}
          <img src={BenefitsIllustration} />{" "}
        </Grid>
      </Grid>
    </div>
  );
};

export default Benefits;
