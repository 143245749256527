import React, { useState } from "react";
import { createBrowserHistory } from "history";
import cookie from "js-cookie";

import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from "./pages/home";
import Layout from "./components/layout";
import Service from "./pages/service";
import { useLayoutEffect } from "react";
import CaseStudy from "./pages/caseStudy";
import Contact from "./pages/contact";

const hist = createBrowserHistory();

function App() {
  const [user, setUser] = useState("");
  const token = cookie.get("token");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const Loader = () => (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <BrowserRouter history={hist}>
      <Wrapper>
        <React.Suspense fallback={<Loader />} />
        <ToastContainer />
        <Layout>
          <ReactRoutes>
            <Route path="*" element={<Home />} />
            <Route path="/service/:id" element={<Service />} />
            <Route path="/case-study/:id" element={<CaseStudy />} />
            <Route path="/contact" element={<Contact />} />
          </ReactRoutes>
        </Layout>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
